import { FC, SVGAttributes } from 'react';

const SearchIcon: FC<SVGAttributes<{}>> = (props: SVGAttributes<{}>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M21.2929 22.7071C21.6834 23.0976 22.3166 23.0976 22.7071 22.7071C23.0976 22.3166 23.0976 21.6834 22.7071 21.2929L21.2929 22.7071ZM17.4706 10.2353C17.4706 14.2312 14.2312 17.4706 10.2353 17.4706V19.4706C15.3358 19.4706 19.4706 15.3358 19.4706 10.2353H17.4706ZM10.2353 17.4706C6.23935 17.4706 3 14.2312 3 10.2353H1C1 15.3358 5.13478 19.4706 10.2353 19.4706V17.4706ZM3 10.2353C3 6.23935 6.23935 3 10.2353 3V1C5.13478 1 1 5.13478 1 10.2353H3ZM10.2353 3C14.2312 3 17.4706 6.23935 17.4706 10.2353H19.4706C19.4706 5.13478 15.3358 1 10.2353 1V3ZM15.4105 16.8248L21.2929 22.7071L22.7071 21.2929L16.8248 15.4105L15.4105 16.8248Z"
            fill="#E6E6E6"
        />
    </svg>
);

export default SearchIcon;
